<template>
  <v-card>
    <v-card-title>問い合わせ-新規</v-card-title>
    <template>
      <v-spacer></v-spacer>
      <template v-if="apierror.status == 'error'">
        <div
          v-for="msg of apierror.messages"
          :key="msg"
        >
          <v-row class="ml-6 mb-3 ma-3">
            <span style="color: red">* {{ msg }} </span>
          </v-row>
        </div>
      </template>
      <template>
        <v-spacer></v-spacer>
        <v-form class="ma-6">
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label>氏名<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="$v.faqForm.faq_sei.$model"
                outlined
                dense
                :error-messages="seiErrors"
                placeholder="姓"
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="$v.faqForm.faq_mei.$model"
                outlined
                dense
                :error-messages="meiErrors"
                placeholder="名"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label>氏名(カタカナ)</label>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="$v.faqForm.faq_sei_kana.$model"
                outlined
                dense
                :error-messages="seikanaErrors"
                placeholder="セイ"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="$v.faqForm.faq_mei_kana.$model"
                outlined
                dense
                :error-messages="meikanaErrors"
                placeholder="メイ"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="faq_tel">問い合わせ状態<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-autocomplete
                v-model="$v.faqForm.faq_code.$model"
                :error-messages="codeError"
                :items="statuss"
                placeholder="状態"
                outlined
                clearable
                dense
                hide-details="auto"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="faq_tel">電話番号<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="$v.faqForm.faq_tel.$model"
                outlined
                dense
                :error-messages="telError"
                placeholder="電話番号"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="faq_email">メールアドレス<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="faqForm.faq_email"
                :error-messages="emailErrors"
                placeholder="メールアドレス"
                hide-details="auto"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label>問い合わせ日<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-menu
                v-model="datePickMenu"
                :close-on-content-click="false"
                :nudge-right="30"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="$v.faqForm.faq_date.$model"
                    :error-messages="dateError"
                    clearable
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    hide-details="auto"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="$v.faqForm.faq_date.$model"
                  @input="datePickMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="faq_content">問い合わせ内容<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-textarea
                v-model="$v.faqForm.faq_content.$model"
                :error-messages="contentErrors"
                name="note"
                hide-details="auto"
                outlined
                :counter="255"
                dense
                rows="5"
                placeholder="問い合わせ内容"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="faq_memo">処理メモ</label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-textarea
                v-model="$v.faqForm.faq_memo.$model"
                :error-messages="memoError"
                name="note"
                hide-details="auto"
                outlined
                :counter="255"
                dense
                rows="5"
                placeholder="処理メモ"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              offset-md="5"
              cols="12"
            >
              <v-btn
                color="primary"
                @click="submit(faqForm)"
              >
                Submit
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                @click="cancelClick"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </template>
  </v-card>
</template>

<script>
/* eslint-disable */
import { mapActions } from 'vuex'
import { required, maxLength, numeric, email } from 'vuelidate/lib/validators'
/* eslint-disable */

export default {
  data: () => ({
    changeFlag: false,
    datePickMenu: '',
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    statuss: [
      {
        text: '未処理', value: 0,
      },
      {
        text: '受理', value: 1,
      },
      {
        text: '回答済', value: 2,
      },
      {
        text: '要確認', value: 3,
      },
      {
        text: '要追跡', value: 4,
      },
    ],
    faqForm: {
      faq_sei: '',
      faq_mei: '',
      faq_sei_kana: '',
      faq_mei_kana: '',
      faq_code: '',
      faq_tel: '',
      faq_email: '',
      faq_date: '',
      faq_content: '',
      faq_memo: '',
    },
  }),
  validations: {
    faqForm: {
      faq_sei: {
        required,
        maxLength: maxLength(20),
      },
      faq_mei: {
        required,
        maxLength: maxLength(20),
      },
      faq_sei_kana: {
        maxLength: maxLength(50),
      },
      faq_mei_kana: {
        maxLength: maxLength(50),
      },
      faq_code: {
        required,
      },
      faq_tel: {
        required,
      },
      faq_email: {
        required,
        email,
      },
      faq_date: {
        required,
      },
      faq_content: {
        required,
        maxLength: maxLength(255),
      },
      faq_memo: {
        maxLength: maxLength(255),
      },
    },
  },
  computed: {
    seiErrors() {
      const errors = []
      if (!this.$v.faqForm.faq_sei.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.faqForm.faq_sei.maxLength && errors.push('最大20文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.faqForm.faq_sei.required && errors.push('必須項目')

      return errors
    },
    meiErrors() {
      const errors = []
      if (!this.$v.faqForm.faq_mei.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.faqForm.faq_mei.maxLength && errors.push('最大20文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.faqForm.faq_mei.required && errors.push('必須項目')

      return errors
    },
    seikanaErrors() {
      const errors = []

      if (!this.$v.faqForm.faq_sei_kana.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.faqForm.faq_sei_kana.maxLength && errors.push('最大50文字までです！')

      return errors
    },
    meikanaErrors() {
      const errors = []

      if (!this.$v.faqForm.faq_mei_kana.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.faqForm.faq_mei_kana.maxLength && errors.push('最大50文字までです！')

      return errors
    },
    codeError() {
      const errors = []

      if (!this.$v.faqForm.faq_code.$dirty) return errors
      // // eslint-disable-next-line no-unused-expressions
      !this.$v.faqForm.faq_code.required && errors.push('必須項目')

      return errors
    },
    telError() {
      const errors = []

      if (!this.$v.faqForm.faq_tel.$dirty) return errors
      // // eslint-disable-next-line no-unused-expressions
      !this.$v.faqForm.faq_tel.required && errors.push('必須項目')

      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.faqForm.faq_email.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.faqForm.faq_email.email && errors.push('正しくメールアドレスを入力してください。')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.faqForm.faq_email.required && errors.push('必須項目')

      return errors
    },
    dateError() {
      const errors = []
      if (!this.$v.faqForm.faq_date.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.faqForm.faq_date.required && errors.push('必須項目')

      return errors
    },

    contentErrors() {
      const errors = []
      if (!this.$v.faqForm.faq_content.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.faqForm.faq_content.maxLength && errors.push('最大255文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.faqForm.faq_content.required && errors.push('必須項目')

      return errors
    },

    memoError() {
      const errors = []
      if (!this.$v.faqForm.faq_memo.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.faqForm.faq_memo.maxLength && errors.push('最大255文字までです！')

      return errors
    },
  },
  watch: {
    faqForm: {
      handler() {
        this.changeFlag = true

      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('faqStore', ['createFaq']),

    jumpToListPage() {
      this.$router.push({ path: '/faq-list', query: this.$route.params.query ?? {} })
    },
    cancelClick() {
      if (this.changeFlag) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }
      
        return
      }
      this.jumpToListPage()
    },
    submit(faqForm) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.createFaq(faqForm)
        .then(() => {
          this.jumpToListPage()
        })
        .catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          }
        })
        .finally(()=>{
          this.submitStatus = false
        })
      } else {
        console.log('error submit!!')
      }
    },
  },
}
</script>
